import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, Tabs, Tab, Chip } from "@material-ui/core";
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles";
import httpClient from "../../utils/http";
import Layout from "../../components/Layout";
import moment from "moment"
import "moment/locale/th"
moment.locale("th")

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(3),
    borderRadius: "10px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  image: {
    width: "200px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
  tabContent: {
    marginTop: theme.spacing(2),
  },
  linkButton: {
    textDecoration: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "8px 16px",
    borderRadius: "4px",
    textAlign: "center",
    display: "inline-block",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Optional: Darker background on hover
      color: "#fff", // Ensures text color remains white
      textDecoration: "none",
    },
  },
}));

const MyBooking = () => {
  const classes = useStyles();
  const [bookings, setBookings] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const data = await httpClient.get(
          "/bookings/owner",
          { show_payment: true, show_house: true },
          true
        );
        setBookings(data);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, []);

  const getStatusLabel = (status) => {
    switch (status) {
      case "pending":
        return "รอชำระเงิน";
      case "paid":
        return "การจองสำเร็จ";
      case "cancel":
      case "reject":
      case "delete":
        return "การจองถูกยกเลิก";
      default:
        return status;
    }
  };
  
  const getStatusStyle = (status) => {
    switch (status) {
      case "pending":
        return {
          backgroundColor: "#FFF7CC", // สีเหลืองอ่อน
          color: "#A67C00", // สีข้อความเหลืองเข้ม
        };
      case "paid":
        return {
          backgroundColor: "#EAF8E6", // สีเขียวอ่อน
          color: "#2F6635", // สีข้อความเขียวเข้ม
        };
      case "cancel":
      case "reject":
      case "delete":
        return {
          backgroundColor: "#FDECEA", // สีแดงอ่อน
          color: "#B02E0C", // สีข้อความแดงเข้ม
        };
      default:
        return {
          backgroundColor: "#E0E0E0", // สีเทาอ่อน
          color: "#616161", // สีข้อความเทาเข้ม
        };
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const now = new Date();

  // กรองรายการตามแท็บที่เลือก
  const filteredBookings = (activeTab === 0
    ? bookings.filter((booking) => new Date(booking.end_date) >= now) // กำลังจะมาถึง
    : bookings.filter((booking) => new Date(booking.end_date) < now) // เสร็จสมบูรณ์
  ).sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  return (
    <Layout page="my-booking">
      <div className={classes.container}>
        {/* Tabs สำหรับ Filter */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="กำลังจะมาถึง" />
          <Tab label="เสร็จสมบูรณ์" />
        </Tabs>

        {/* แสดงรายการจอง */}
        <div className={classes.tabContent}>
          {filteredBookings.map((booking) => (
            <Card className={classes.card} key={booking.id}>
              <Grid container style={{marginTop: "10px", marginBottom: "5px"}}>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  <Typography variant="body2" style={{ marginLeft: "10px" }}>หมายเลข ID: {booking.bid}</Typography>
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Chip
                  label={getStatusLabel(booking.status)}
                  size="small"
                  style={{
                    ...getStatusStyle(booking.status),
                    fontWeight: "bold",
                    // fontSize: "14px",
                    // padding: "5px 10px",
                    borderRadius: "8px",
                    marginRight: "10px"
                  }}
                />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ margin: "0px", borderTop: "solid lightgray", borderBottom: "solid lightgray" }}>
                <Grid item xs={3}>
                  <img
                    src={
                      booking.house.house_images.find((img) => img.is_highlight)?.url ||
                      booking.house.house_images.find((img) => img.sequence === 1)?.url
                    }
                    alt={booking.house.name}
                    className={classes.image}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="subtitle1">{booking.house.name}</Typography>
                  <Typography variant="body2">
                    เช็คอิน: {moment(booking.start_date).format("DD MMM YYYY")}
                  </Typography>
                  <Typography variant="body2">
                    เช็คเอาต์: {moment(booking.end_date).format("DD MMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}
                >
                  {booking.status === "pending" ? (
                    <Link
                      to={`/Booking/detail/?bid=${booking.bid}`}
                      className={classes.linkButton}
                    >
                      ชำระเงิน
                    </Link>
                  ) : booking.status !== "reject" &&
                    booking.status !== "delete" &&
                    booking.status !== "cancel" ? (
                    <Link
                      to={`/Booking/detail/?bid=${booking.bid}`}
                      className={classes.linkButton}
                    >
                      ดูข้อมูลเพิ่มเติม
                    </Link>
                  ) : null}
                </Grid>
              </Grid>
            </Card>
          ))}

          {/* กรณีไม่มีข้อมูล */}
          {!filteredBookings.length && (
            <Typography variant="body2" align="center" color="textSecondary">
              ไม่พบข้อมูลการจอง
            </Typography>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MyBooking;